import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useRef } from "react";
import bcrypt from 'bcryptjs';
import { toast } from 'react-toastify';
import { PoojaContext } from '../../Context/PoojaContext';
import { useAuthentication } from '../../Context/AuthenticationContext';


export const Login = ({ setSignUpShow }) => {
  const { login,user } = useAuthentication();
  const {updateLoginUserDetails} = useContext(PoojaContext);
  const userName = useRef();
  const password = useRef();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const URL = `${baseURL}/user/login`;
  const role = user.role;


  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailAddress = userName.current.value;
    const enteredPassword = password.current.value;
    const authDetail = {
      emailAddress: emailAddress
    }

    try {

      const response = await axios.post(URL, authDetail);
      
      if (response) {
        
        if (response.status === 200) {

          const logindetails = response.data;
          
          if (logindetails != null) {
            const passwordCompare = bcrypt.compareSync(enteredPassword, logindetails.password);
            if (passwordCompare) {

              updateLoginUserDetails(logindetails);
              login(logindetails.token, logindetails.username, logindetails.userId, logindetails.role);
              
              if(logindetails.role === "ADMIN") {
                
                navigate("/addproducts");
              } else {
                navigate("/dashboard");
              }
              
            } else {
              toast.error("Login failed - Invalid Credentials")
            }

          }else {
            toast.error('Login Failed - Try again with valid credentials')
          }
        }
      }

    } catch (error) {

      if (!error?.response) {
        toast.error('No Server Response');
      } else {
        if (error.response.data.message != null) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Login Failed - Try again with valid credentials')
        }
          
      }

    }
  };

  return (
    <Form sm={12} md={6} className='login-signup-container' onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label >UserId</Form.Label>
        <Form.Control ref={userName} className="login-signup-fields" type="text" required placeholder="Enter email/Mobile Number" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control ref={password} className="login-signup-fields" type="password" required placeholder="Password" />
      </Form.Group>

      <Button variant="secondary" type="submit">
        Submit
      </Button>
      <Form.Label className="login-signup-label">New to UthsavaSoukhya? <Link onClick={() => { setSignUpShow(true) }} to="/login">Signup Now </Link></Form.Label>

    </Form>

  )
}
