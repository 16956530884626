import { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { PoojaContext } from './Context/PoojaContext';

import Cart from './Pages/Cart';
import { Celebrations } from './Pages/Celebrations';
import LoginSignUp from './Pages/LoginSignUp';
import PoojaCategory from './Pages/PoojaCategory';
import Product from './Pages/Product';
import { getCategoriess,  getProducts } from './Services/ApiService';

import metal_banner from './Components/Assets/metal_items_banner.jpeg';
import milk_banner from './Components/Assets/milk_banner.png';
import { CelebrationDisplay } from './Components/Celebrations/CelebrationDisplay';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import { OrderSummary } from './Components/Order/OrderSummary';
import { ProtectedRoute } from './Components/ProtectedRoute/ProtectedRoute';
import { Search } from './Components/Search/Search';
import { Dashboard } from './Components/UserDashBoard/Dashboard';
import Home from './Pages/Home';
import { PageNotFound } from './Pages/PageNotFound';
import { ProductUpload } from './Components/AdminPages/ProductUpload';
import { Loading } from './Components/Loading';
import axios from 'axios';

import ReactGA from "react-ga4";



function App() {

  const [loading, setLoading] = useState(false);
  const {  updateProducts,  updateCategories  } = useContext(PoojaContext);

  const MEASUREMENT_ID ="G-8JCCD6FHNS";
  ReactGA.initialize(MEASUREMENT_ID);
  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Home Page" });

  useEffect(() => {
    const fetchProductData = async () => {
      
      await getProducts()
      .then((data) => {
        updateProducts(data);
      })
      .catch((err) => toast.error(err));
    }
    async function fetchCategoriesData() {
      try {
        const categories = await getCategoriess();
        updateCategories(categories);
      } catch (error) {
        toast.error('Error fetching categories:', error);
      }
    }
  
    fetchProductData();
    fetchCategoriesData();
  }, []);

  useEffect(() => {
    //request interseptor
    axios.interceptors.request.use((config) => {
      setLoading(true);
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    //response interseptor
    axios.interceptors.response.use((config) => {
      setLoading(false);
      return config;
    }, (error) => {
      setLoading(false);
      return Promise.reject(error);
    });

  },[])



  return (
    <BrowserRouter>
    <Container fluid>
     {/* <Row>
        <Col>
          <Search/>
        </Col>
      </Row>*/}
          <Row  >
            <Col className="header"><Navbar/></Col>
          </Row>
          <Row>
            <Col className="body">
            <Loading show={loading}/>         
            <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/metal' element={<PoojaCategory banner={metal_banner} category="Metal"/>}/>
            <Route path='/milk' element={<PoojaCategory banner={milk_banner} category="Milk"/>}/>
            <Route path='/celebrations' element={<Celebrations />}>
                <Route path=':celebrationsId' element={<CelebrationDisplay />}/>
             </Route>
           {/** <Route path='/product' element={<Product/>}>*/}
            <Route path='/product/:productId' element={<Product/>}/>
             {/** </Route>*/}
            <Route path='/addproducts' element={<ProtectedRoute><ProductUpload/></ProtectedRoute>}/>
            <Route path='/dashboard' element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
            <Route path='/cart' element={<ProtectedRoute><Cart/></ProtectedRoute>}/>
            <Route path="order-summary" element={<ProtectedRoute><OrderSummary /></ProtectedRoute>} />
            <Route path='/login' element={<LoginSignUp/>}/>
            <Route path="*" element={<PageNotFound/>} />
            
          </Routes>
            </Col>
            
          </Row>
          <Row>
            <Col className='footer'>
            <Footer/>
            </Col>
          </Row>
        </Container>
        </BrowserRouter>
      );
    }
    
    export default App;
