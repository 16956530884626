import { Navigate } from 'react-router-dom';
import { useAuthentication } from '../../Context/AuthenticationContext';

export const ProtectedRoute = ({children}) => {
  const {  user } = useAuthentication();
    const token = sessionStorage.getItem("token");
    console.log(token)

  return (
    token ? children : <Navigate to="/login" />
  )
}
